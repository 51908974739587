import { useRouter } from "next/router";

import { useGetTheatreMode }                   from "_common/hooks/global";
import { isCreatorDashboardPage, isPageRoute } from "_common/utils";

import Presentation from "./presentation";

const component = ( { children } ) => {
	const router            = useRouter ();
	const theatreModeToggle = useGetTheatreMode ();

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const isCreatorDashboard = isCreatorDashboardPage ( { router } );

	return (
		<Presentation
			checkPageRoute     = { checkPageRoute }
			headerOnlyLayout   = { checkPageRoute ( "growth-dashboard" ) }
			isCreatorDashboard = { isCreatorDashboard }
			isStreamer         = { checkPageRoute ( "studio" ) }
			theatreModeToggle  = { theatreModeToggle }
		>
			{children}
		</Presentation>
	);
};

export default component;
