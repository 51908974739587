import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		childMainContainer: {
			display     : "flex",
			marginTop   : !props?.theatreModeToggle ? "70px" : "0",
			paddingLeft : !props?.theatreModeToggle ? "6rem" : "0",

			[rooterMediaQuery.tablet]: {
				paddingLeft: !props?.theatreModeToggle ? "6rem" : "0"
			},

			[rooterMediaQuery.mobile]: {
				paddingLeft: 0
			}

			/* [theme.fn.largerThan ( maxWidth )]: {
        maxWidth : maxWidth + "px",
        margin   : "auto"
      } */

		},
		childContainer: {
			width: "100%"
		},
		footerContianer: {
			paddingLeft: "6rem"
		},
		creatorDashLayout: {
			display   : "flex",
			marginTop : "70px",
			overflow  : "hidden"
		},
		creatorDashMobileLayout: {
			display       : "flex",
			flexDirection : "column",
			marginTop     : "70px",
			paddingBottom : "4.5rem",
			height        : "calc(100vh - 70px)"
		}
	};
} );
